import React from 'react';
import { renderToString } from 'react-dom/server'

import { Entry } from './entry';

const archiveName = 'journal-archive';

function pad(number) {
  if (number < 10) {
    return '0' + number;
  }
  return number;
}
const cleanTitle = title => title.split(' ').join('_').replace(/\W/g, '').toLowerCase();
const makeDateString = date => `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())}`;
const makeFileName = (entry) => `${makeDateString(new Date(entry.date))}.${entry.id}.${cleanTitle(entry.title)}`;

const handleImages = async (entry) => {
  if (entry.images.length === 0) return [];

  const imageFiles = await Promise.all(entry.images.map(
    async image => {
      try {
        const response = await fetch(image.externalUrl);
        const imageBlob = await response.blob();
        const fileName = `images/${image.id}.${image.externalUrl.split('.').pop()}`;
        image.externalUrl = '../' + fileName;
        imageBlob.name = `${archiveName}/${fileName}`;
        return imageBlob;
      } catch (e) {
        console.error(e);
        return null;
      }
    }
  ));

  return imageFiles
    .filter(image => image !== null);
}

const downloadEntries = async (entries) => {
  const fileStream = window.streamSaver.createWriteStream(`${archiveName}.zip`)

  const index = new File([renderToString(<IndexPage entries={entries} />)], `${archiveName}/index.html`)
  const filePromises = entries
    .sort((a, b) => a.date < b.date ? 1 : -1)
    .map(async entry => {
      const fileName = makeFileName(entry);
      const imageFiles = await handleImages(entry, fileName);
      const content = renderToString(<Entry entry={entry} />);

      return [
        new File([content], `${archiveName}/entries/${fileName}.html`),
        new File([JSON.stringify(entry)], `${archiveName}/json/${fileName}.json`),
        ...imageFiles,
      ];
    })

  const files = (await Promise.all(filePromises)).flatMap(i => i);

  const readableZipStream = new window.ZIP({
    start(ctrl) {
      ctrl.enqueue(index)
      ctrl.enqueue({ name: 'journal-archive/images', directory: true })
      ctrl.enqueue({ name: 'journal-archive/entries', directory: true })
      ctrl.enqueue({ name: 'journal-archive/json', directory: true })
      files.forEach(file => ctrl.enqueue(file));
      ctrl.close()
    },
  })

  // more optimized
  if (window.WritableStream && readableZipStream.pipeTo) {
    return readableZipStream.pipeTo(fileStream).then(() => console.log('done writing'))
  }

  // less optimized
  const writer = fileStream.getWriter()
  const reader = readableZipStream.getReader()
  const pump = () => reader.read()
    .then(res => res.done ? writer.close() : writer.write(res.value).then(pump))

  pump()
}

export function DownloadFiles({ entries }) {
  const handleClick = () => {
    downloadEntries(entries);
  }

  return (
    <button onClick={handleClick} className="button">Download</button>
  );
}

function IndexPage({ entries }) {
  return (
    <table>
      <tr>
        <th>Date</th>
        <th>Title</th>
      </tr>
      <tbody>
        {entries.map(entry => (
          <tr key={entry.id}>
            <td>{makeDateString(new Date(entry.date))}</td>
            <td>
              <a href={`./entries/${makeFileName(entry)}.html`}>
                {entry.title || 'No Title'}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
