import React from 'react';
import './App.css';
import { DownloadFiles } from './download-files-button';
import { Entry } from './entry';

const defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}

const baseConfig = {
  method: 'GET',
  headers: defaultHeaders,
};

const handleResponse = async (response) => {
  const isError = (
    response.ok === false ||
    response.status < 200 ||
    response.status >= 300
  ) && response.status !== 304;
  let output;

  try {
    output = await response.json();
  } catch (e) {
    output = response;
  }

  if (isError) {
    const error = {
      status: response.status,
      message: output.message || response.statusText,
    };
    throw error;
  }

  return output;
};

export const fetchApi = async (route, config) => {
  const url = 'https://api.emersonjournal.com/' + route;
  const options = { ...baseConfig, ...config };

  if (options.body) {
    options.body = JSON.stringify(options.body);
    options.headers['Content-Type'] = 'application/json';
  }
  const response = await fetch(url, options);
  return handleResponse(response);
};

async function loadUserData({ token, userId }) {
  const authConfig = {
    ...baseConfig,
    headers: {
      ...baseConfig.headers,
      Authorization: `Bearer ${token}`,
    }
  }
  const [user, { entries }, { images }] = await Promise.all([
    fetchApi('users/' + userId, authConfig),
    fetchApi('entries', authConfig),
    fetchApi('images', authConfig),
  ]);

  return { user, entries, images };
}

function LoginForm({ logIn }) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const submitForm = async (e) => {
    e.preventDefault();
    const response = await fetchApi('tokens', {
      method: 'POST',
      body: {
        email,
        password,
      }
    });

    logIn(response)
  }

  return (
    <form onSubmit={submitForm} className="input-form">
      <div>
        <label htmlFor="input-email" hidden>Email</label>
        <input type="email" id="input-email" placeholder="Email" className="input" onChange={e => setEmail(e.target.value)} value={email} />
      </div>
      <div>
        <label htmlFor="input-password" hidden>Password</label>
        <input type="password" id="input-password" placeholder="Password" className="input" onChange={e => setPassword(e.target.value)} value={password} />
      </div>
      <div>
        <input type="submit" value="Log In" className="button" />
      </div>
    </form>
  );
}

function JournalEntries({ entries }) {
  return (
    <>
      <DownloadFiles entries={entries} />
      {entries.sort((a, b) => a.date < b.date ? 1 : -1).map((entry, index) => (
        <React.Fragment key={entry.id}>
          <Entry entry={entry} />
        </React.Fragment>
      ))}
    </>
  );
}

function App() {
  const [authToken, setAuthToken] = React.useState();
  const [userId, setUserId] = React.useState();
  const [entries, setEntries] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!authToken || !userId) { return; }

    const loadData = async () => {
      setLoading(true);

      const data = await loadUserData({ token: authToken, userId });

      setEntries(data.entries.map(entry => ({
        ...entry,
        images: data.images.filter(image => image.entryId === entry.id),
      })));

      setLoading(false);
    }

    loadData();
  }, [userId, authToken])

  const logIn = async ({ token, userId }) => {
    setAuthToken(token);
    setUserId(userId);
  }

  return (
    <div className="App">
      <header>
        <h1>Emerson Journal</h1>
        <h3>Export Utility</h3>
      </header>
      <div>
        {authToken
          ? loading ? <span>Loading entries...</span> : <JournalEntries entries={entries} />
          : <LoginForm logIn={logIn} />}
      </div>
    </div>
  );
}

export default App;
