import React from 'react';

export function Entry({ entry }) {
  return (
    <div className={'entry'}>
      <h1>{entry.title}</h1>
      <h4>Date: {new Date(entry.date).toLocaleString()}</h4>
      <h4>Location: {entry.location.name}</h4>
      <p>{entry.body}</p>
      {
        entry.images.map(image => (
          <React.Fragment key={image.id}>
            <img src={image.externalUrl} height={400} />
          </React.Fragment>
        ))
      }
    </div>
  )
}
